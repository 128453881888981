import { Col, Row, Divider } from "antd";
import React from "react";
import "antd/dist/antd.min.css";
import "./Insight.scss";
import AOS from 'aos';
import 'aos/dist/aos.css';


const Insight = () => {
  AOS.init()
  return (
    <div className="insight-main" id="about-screen">
      <Row>
        <Col span={3}></Col>
        <Col className="insight-label" data-aos="zoom-in"  data-aos-delay='300' data-aos-anchor-easing='ease-in-out'>About</Col>
      </Row>
      <Row>
        <Col span={3}></Col>
        <Col xs={{ span: 16 }} md={{ span: 8 }} className='notes-col'>
          <Col className="col-1" data-aos="zoom-in"  data-aos-delay='300' data-aos-anchor-easing='ease-in'>
            <div className="notes" >
              Everything about a startup is difficult, the biggest battle
              founders fight is the one they fight alone.
            </div>
            <div className="details-notes">
              A little help in figuring out the right problem to go after,
              figuring out PMF, crafting GTM strategy & communication, scaling
              tactics, investment guidance and acquisition knowhow can go a long
              way.
            </div>
            <div className="details-notes">
              We all have shared/similar background, let’s take it one step
              further. Let’s find reasons to collaborate, help each other with
              wisdom and show a community can spur creations!
            </div>
          </Col>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 11 }} style={{ padding: "2em 0" }}>
          <Row>
            <Col data-aos="zoom-in"  data-aos-delay='300' data-aos-anchor-easing='ease-in'>
              {[...Array(4)].map((e, i) => {
                return (
                  <Row style={{ marginLeft: "50px" }} key={i}>
                    <Col className="col-1" style={{ marginRight: "60px" }}  >
                      <div className="hasTagDiv" data-aos="zoom-in">
                        {i === 0 ? (
                          <p className="hashTag">Founders</p>
                        ) : i === 1 ? (
                          <p className="hashTag">Investors</p>
                        ) : i === 2 ? (
                          <p className="hashTag">Executives</p>
                        ) : i === 3 ? (
                          <p className="hashTag">Corp Dev</p>
                        ) : (
                          ""
                        )}
                        <p style={{ width: "100px" }}></p>
                      </div>
                    </Col>
                    <Col className="col-1" span={14}>
                      <div className="hasTagDiv">
                        {/* <p className="hashTag2"> */}
                        {i === 0 ? (
                          <p className="hashTag2">
                            Get guidance, design partners, investment advice,
                            customers and partnerships
                          </p>
                        ) : i === 1 ? (
                          <p className="hashTag2">
                            Find thriving startups, phenomenal founders and
                            latest startup trends
                          </p>
                        ) : i === 2 ? (
                          <p className="hashTag2">
                            Lead the industry as an early adopter with the new
                            products and technologies
                          </p>
                        ) : i === 3 ? (
                          <p className="hashTag2">
                            Discover the startup that could align well with the
                            corporate objectives
                          </p>
                        ) : (
                          ""
                        )}
                        {/* </p> */}
                      </div>
                    </Col>
                    {i !== 3 && <Divider />}
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Insight;
