import React from "react";
import HeaderComponent from "./common/header";
import Features from "./componants/Features";
import Insight from "./componants/Insight";
import Container from "./containers";
const App = () => {
  return (
    <div style={{height: '100%'}}>
      {/* <HeaderComponent/>
      <Insight/>
      <Features/> */}
      <Container/>
    </div>
  );
}

export default App;
