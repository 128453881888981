import React, { useState } from "react";
import "antd/dist/antd.min.css";
import "./HeaderComponent.scss";
import { FundaIcon, joinModalIcon } from "../../assets";
import { useEffect } from "react";
import { Layout, Tabs, Modal, Button } from "antd";
import { gapi } from 'gapi-script';
import { SignInFlow, useUser } from '@descope/react-sdk'
import logoColor from '../../assets/logo-color.png'

const { Header } = Layout;
var sticky = 0;
const HeaderComponent = ({ registrationForm, showRegistrationForm }) => {
  const [screenKey, setScreenKey] = useState('home');
  const [userLoggedInGoogle, setUserLoggedInGoogle] = useState(false);
  const [userLoggedInLinkedIn, setUserLoggedInLinkedIn] = useState(false);
  const [offsetJoin, setOffsetJoin] = useState();
  const clientId = '427157627233-f0oc8vm2dm5c3lq7a6ao37ts631nuuln.apps.googleusercontent.com'; // Amol client id for google
  // const clientId = '91227323569-l7ho8let5sjsdf37rt6607g0v0qkdpa9.apps.googleusercontent.com';   // Venkatesh client id for google

  const { user } = useUser();

  const onChange = (key) => {
    setScreenKey(key)
    if (key === 'home') {
      let screenPosition = document.getElementById("home-screen");
      window.scrollTo(0, screenPosition.offsetTop);
    }
    if (key === 'about') {
      let screenPosition = document.getElementById("about-screen");
      window.scrollTo(0, screenPosition.offsetTop);
    }
    if (key === 'mission') {
      let screenPosition = document.getElementById("mission-screen");
      window.scrollTo(0, screenPosition.offsetTop);
    }
    if (key === 'eventAgenda') {
      let screenPosition = document.getElementById("event-agenda");
      window.scrollTo(0, screenPosition.offsetTop);
    }
    if (key === 'sponsor') {
      let screenPosition = document.getElementById("events-screen");
      window.scrollTo(0, screenPosition.offsetTop);
    }
    if (key === 'contact') {
      let screenPosition = document.getElementById("regstration-screen");
      window.scrollTo(0, screenPosition.offsetTop + 80);
    }
    if (key === 'members') {
      let screenPosition = document.getElementById("members-screen");
      window.scrollTo(0, screenPosition.offsetTop);
    }
  };
  window.addEventListener("scroll", (event) => {
    let scroll = window.scrollY + 50;
    let aboutScreen = document.getElementById("about-screen");
    let missionScreen = document.getElementById("mission-screen");
    let eventAgendaScreen = document.getElementById("event-agenda");
    let registrationScreen = document.getElementById("regstration-screen");
    let eventsScreen = document.getElementById("events-screen");
    let membersScreen = document.getElementById("members-screen");
    if (aboutScreen.offsetTop >= scroll) {
      setScreenKey('home');
    }
    if (aboutScreen.offsetTop <= scroll && missionScreen.offsetTop >= scroll) {
      setScreenKey('about');
    }
    if (missionScreen.offsetTop <= scroll && eventAgendaScreen.offsetTop > scroll) {
      showRegistrationForm(false)
      setScreenKey('mission');
    }
    if (eventAgendaScreen.offsetTop <= scroll && membersScreen.offsetTop > scroll) {
      showRegistrationForm(false)
      setScreenKey('eventAgenda');
    }
    if (membersScreen.offsetTop <= scroll && eventsScreen.offsetTop > scroll) {
      setScreenKey('members');
    }
    if (eventsScreen.offsetTop <= scroll && registrationScreen.offsetTop > scroll) {
      setScreenKey('sponsor');
    }
    if (registrationScreen.offsetTop <= scroll) {
      setScreenKey('contact');
    }
  });
  var navbar = document.getElementById("head");
  useEffect(() => {
    window.onscroll = function () { myFunction() };
    // console.log('navbar.offsetTop =====> ', navbar?.offsetTop)
    if (navbar && navbar.offsetTop > 0) {
      sticky = navbar.offsetTop;
      // console.log('sticky ==> ', sticky);
      if (sticky === 0) {
      // console.log('sticky 0  and offsetJoin ==> ', offsetJoin);
        sticky = offsetJoin;
      }
    }

    function myFunction() {
      // console.log('window.pageYOffset ===> ', window.pageYOffset);
      if (navbar) {
        if (window.pageYOffset === 0) {
          // console.log('inside window.pageYOffset === 0 removing sticky', sticky);
          navbar.classList.remove("sticky");
        }
        if (window.pageYOffset >= sticky) {
          // console.log('inside window.pageYOffset >= sticky adding sticky', sticky);
          navbar.classList.add("sticky")
        } 
        else {
          // console.log('else ', sticky);
          navbar.classList.remove("sticky");
        }
      }
    }
  }, [window.onscroll]);
  useEffect(() => {
    if (registrationForm) {
      let screenPosition = document.getElementById("regstration-screen");
      if (navbar) {
        let b = navbar?.offsetTop;
        // console.log('b ===> ', b);
        setOffsetJoin(b);
      }
      window.scrollTo(0, screenPosition.offsetTop);
      setScreenKey('contact');
    }
  }, [registrationForm]);
  let userRecord = JSON.parse(localStorage.getItem('userRecord'));
  let googleLoggedIn = JSON.parse(localStorage.getItem('googleLogin'));
  return (
    <Layout
      style={{
        overflow: "hidden",
      }}
    >
      <Modal title="Join the Community" open={!googleLoggedIn && registrationForm && !userRecord} footer={false} onCancel={() => showRegistrationForm(false)} centered>
        <div className="modal-outer-border"></div>
        <div className='modal-main-div' style={{ display: 'flex', flexDirection: 'column',alignItems: 'center', zIndex: 99}}>
          <div className="join-funda-hands">
            <img src={joinModalIcon} alt="FUNDA-For the Indian founders by the Indian founders join-funda" />
          </div>
          <p className="join-us-google">
            Join us with google and Fill out the form
          </p>
          <SignInFlow
            onSuccess={(e) => {
              showRegistrationForm(false)
              localStorage.setItem("googleLogin", JSON.stringify(e.detail.user))
            }}
            onError={(e) => console.log('Could not logged in!')}
          />
        </div>
      </Modal>
      <Header
        theme="light"
        className="header"
        style={{
          padding: 0,
        }}
      >
        <div id="head" className="header-main">
          <span className='logo' onClick={() => onChange('home')} style={{cursor: 'pointer'}}><img src={logoColor} height={20} width={60} alt="FUNDA-For the Indian founders by the Indian founders" /></span>
          <Tabs
            // defaultActiveKey="1"
            activeKey={screenKey}
            onChange={onChange}
            items={[
              {
                label: `Home`,
                key: 'home',
              },
              {
                label: `About`,
                key: 'about',
              },
              {
                label: `Mission`,
                key: 'mission',
              },
              {
                label: `Events`,
                key: 'eventAgenda',
              },
              {
                label: `Members`,
                key: 'members',
              },
              {
                label: `Sponsor`,
                key: 'sponsor',
              },
              {
                label: `Contact`,
                key: 'contact',
              },
            ]}
          />
          <div className="share-social">
            {
              userRecord && userRecord.Email ? 
              <p>{'Welcome ' + userRecord['First Name'] + ' !'}</p>
            :
            <>
              <p>
                {localStorage.getItem('googleLogin') || userLoggedInLinkedIn ?
                  googleLoggedIn.name != '' ? 
                  'Welcome ' + JSON.parse(localStorage.getItem('googleLogin')).name + ' !'
                  :
                  'Welcome ' + JSON.parse(localStorage.getItem('googleLogin')).email + ' !'
                  : 
                  <Button className="join-community" onClick={() => showRegistrationForm(true)}>
                      Join the Community
                  </Button>}
              </p>
            </>
            }
          </div>
        </div>
      </Header>
    </Layout>
  );
};

export default HeaderComponent;
