import retry from "async-retry";

export const getUserData = async () => {
    try {
      return await retry(
        async () => {
          const response = await fetch('https://api.airtable.com/v0/appKeFHAbXylu2KpH/Community', {
            headers:{
                "Accept": "*/*",
                "Authorization":`Bearer patGd8stIjd8zm9tN.aecf735deeb79e00f97bdc3c7ef77f808230615353e890a2fbdb55f5526970f4`,
            },
            method: "get",
          });
          return response;
        },
        {
          retries: 1,
        }
      );
    } catch (error) {
      console.error(error);
    }
  };