
import React, { lazy } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { getUserData } from '../services/ApiManager';
import { useSearchParams } from 'react-router-dom';

const Initiative = lazy(() => import('../componants/Initiative'));
const Mission = lazy(() => import('../componants/Mission'));
const EventAgenda = lazy(() => import('../componants/event-agenda/index'));
const Events = lazy(() => import('../componants/Events'));
const Members = lazy(() => import('../componants/Members'));
const HeaderComponent = lazy(() => import('../common/header'));
const Insight = lazy(() => import('../componants/Insight'));
// const Features = lazy(() => import('../componants/Features'));
const FooterComponent = lazy(() => import('../common/footer'));
const Stepper = lazy(() => import('../componants/multistep/Stepper'));

const Container = () => {
    const [searchParams] = useSearchParams();
    const [userIsPresent, setUserIsPresent] = useState(false);
    const [registrationForm, setRegistrationForm] = useState(searchParams.get('descope-login-flow'));
    useEffect(() => {
        getRegisteredUserDetails();
    },[]);
    const getRegisteredUserDetails = async() => {
        let response = await getUserData();
        let resp = await response.json();
        if (response.ok) {
            resp.records.map(record => {
                if (localStorage.getItem('googleLogin') && record.fields.Email == JSON.parse(localStorage.getItem('googleLogin')).email) {
                    localStorage.setItem('userRecord', JSON.stringify(record.fields));
                    setUserIsPresent(true);
                }
            });
            localStorage.setItem('allUsers', JSON.stringify(resp.records));
        }
        // console.log('response ====> ', response);
        // console.log('resp ====> ', resp);
    }
    const showRegistrationForm = (value) => {
        setRegistrationForm(value);
    }
    return (
        <>  
            <Initiative showRegistrationForm={showRegistrationForm}/>
            <HeaderComponent registrationForm={registrationForm} showRegistrationForm={showRegistrationForm}/>
            <Insight />
            <Mission />
            <EventAgenda/>
            <Members/>
            <Events />
            {/* <Features/> */}
            {/* {
                !userIsPresent && <Stepper />
            } */}
            <Stepper userIsPresent={userIsPresent}/>
            <FooterComponent />

        </>
    )
}

export default Container